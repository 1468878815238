<template>
  <div>
    <van-nav-bar
      title="远程病理"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('@/assets/images/swiper.png')"
          alt=""
          style="width: 100%; height: 100%"
      /></van-swipe-item>
    </van-swipe>
    <div class="box">
      <van-grid :column-num="2" gutter="20px" icon-size="48px">
        <van-grid-item
          :icon="require('@/assets/images/icon_myApply.png')"
          text="我的申请"
          to="/reportPathologyMyApplication"
        />
        <van-grid-item
          :icon="require('@/assets/images/icon_applyToMe.png')"
          text="向我申请"
          to="/reportPathologyApplyToMe"
        />
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  margin-top: 50px;
}
 /deep/.van-grid-item__text {
   font-size: 16px;
  }
</style>
